import React from 'react';
import { graphql } from 'gatsby';
import formatDate from 'helpers/formatDate';
import Metas from 'components/metas';
import RichText from 'components/richText';
import styles from './pressRelease.module.scss';

export default function PressRelease({ data }) {
  const formattedDate = formatDate(data.contentfulPressRelease.date, { month: 'long' });

  const metaTitle = data.contentfulPressRelease.metaTitle || data.contentfulPressRelease.title;

  return (
    <>
      <Metas
        title={`${metaTitle}`}
        description={
          data.contentfulPressRelease.metaDescription
            ? data.contentfulPressRelease.metaDescription.metaDescription
            : null
        }
      />
      <article className={styles.container}>
        <header className={styles.header}>
          <h1 className={styles.title}>{data.contentfulPressRelease.title}</h1>
          <div className={styles.date}>{formattedDate}</div>
        </header>
        <RichText className={styles.body} content={data.contentfulPressRelease.copy.json} />
      </article>
    </>
  );
}

export const pageQuery = graphql`
  query PressReleaseQuery($id: String!) {
    contentfulPressRelease(id: { eq: $id }) {
      title
      date
      copy {
        json
      }
      metaTitle
      metaDescription {
        metaDescription
      }
    }
  }
`;
